import React from 'react'
import Seo from "@components/seo"
import Layout from "@layout"
import Header from "@layout/header/header-one"
import Footer from "@layout/footer/footer-one"
import PageHeader from "@components/pageheader";
import AboutArea from "@containers/index-processing/about-area"
import ContactArea from "@containers/index-infotechno/contact-area"
import slide1 from "@assets/images/engineer-meeting-architectural-project-working-with-partner.jpg"
import { useIntl, FormattedMessage } from "gatsby-plugin-react-intl"

const TecknicalAdvice = ({location,pageContext}) => {

    const intl = useIntl()
    const serviceTitles = intl.formatMessage({
      id: "consulting_services",
    })
    const content = [
      {
        image: slide1,
      }
      
    ]
  
    const srviceTexts = [
      {
        text: <FormattedMessage id="technical_advice" />,
        link:"/technical-advice"
      },
      {
        text: <FormattedMessage id="technical_follow_up" />,
        link:"/tecknical-follow-up"
      },
      {
        text: <FormattedMessage id="training_and_qualifying" />,
        link:"/training-and-qualifications"
      },
      
     
    ]
    return ( 
        <Layout location={location}>
        <Seo title="Services" />
        <Header />
        <main className="site-wrapper-reveal">
          {/* <HeroArea /> */}
          <PageHeader
			pageContext={pageContext}
			location={location}
			title="IT Services"
      pageTitle="services"
		/>
          <AboutArea
            content={content}
            srviceTexts={srviceTexts}
           serviceTitles={serviceTitles}
           studyRequestUrl="/consultation-request"
         isStudyRequest={true}
         buttonTextRequest="technical_advice_request"
          />
  
          <ContactArea />
        </main>
        <Footer />
      </Layout> 
     );
}
 
export default TecknicalAdvice;